import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Modal,
  Select,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ITractableTheme, TrashIcon } from "@tractable/frame-ui";
import { colours } from "@tractable/frame-ui/build/theme/colour";
import { Product, User, UserStatus } from "@tractableai/auth-management-client";
import classNames from "classnames";
import { ReactElement, useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { ApiClientContext } from "../context/ApiClientProvider";
import { RuntimeConfigContext } from "../context/ConfigProvider";
import { UserPoolContext } from "../context/UserPoolProvider";
import { userDisplayName } from "../i18n";
import * as notify from "../notifications";
import { AllProducts, getUniqueProductId } from "../products";
import { commonStyles } from "../styles";
import MailIcon from "./icons/MailIcon";
import ResendIcon from "./icons/ResendIcon";

type UserActionsProps = {
  user: User;
  onDeletion: () => Promise<void>;
  className?: string;
};

export type UserAction = "delete" | "reset_password" | "send_invite";
export type ActionDetails = {
  /** Key to discriminate between instances of this type */
  action: UserAction;

  /** Icon to represent the action */
  icon: ReactElement;

  /** Text to display next to the icon */
  text: string;
};

const actions: Record<string, ActionDetails> = {
  delete: {
    action: "delete",
    icon: <TrashIcon colour={colours.grey[50]} />,
    text: "Delete",
  },
  sendInvite: {
    action: "send_invite",
    icon: <MailIcon colour={colours.grey[50]} />,
    text: "Resend invite",
  },
  resetPassword: {
    action: "reset_password",
    icon: <ResendIcon colour={colours.grey[50]} />,
    text: "Reset password",
  },
};

/** Determines which actions are available for a given user status */
const availableUserActions: Record<UserStatus, Array<ActionDetails>> = {
  joined: [actions.resetPassword, actions.delete],
  expired_invite: [actions.sendInvite, actions.delete],
  reset_required: [actions.resetPassword, actions.delete],
  pending: [actions.sendInvite, actions.delete],
  deactivated: [actions.delete],
  external_user: [],
  unknown: [actions.delete],
};

type ResendInviteForm = {
  product: Product;
};

const UserActions: React.FC<UserActionsProps> = ({
  user,
  className,
  onDeletion,
}) => {
  const { apiClient } = ApiClientContext.useValue();
  const { userPool } = UserPoolContext.useValue();
  const config = RuntimeConfigContext.useValue();

  const [loading, setLoading] = useState(false);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [resendModalOpened, setResendModalOpened] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  const hardcodedProducts = AllProducts[config.trEnv];

  const resendForm = useForm<ResendInviteForm>({
    initialValues: { product: products[0] },
  });

  useEffect(() => {
    let isCurrent = true;
    setLoading(true);
    apiClient
      .listProducts()
      .then((response) => {
        // This isn't strictly necessary but is just to eliminate warnings when running the tests.
        // See https://reacttraining.com/blog/setting-state-on-unmounted-component
        // Could be removed after updating to React 18+
        if (isCurrent) {
          setProducts([...hardcodedProducts, ...response.products]);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (isCurrent) {
          setLoading(false);
        }
      });

    return () => {
      isCurrent = false;
    };
  }, []);

  useEffect(() => {
    resendForm.setFieldValue("product", products[0]);
  }, [products]);

  const availableActions = availableUserActions[user.status];
  const classes = useStyles();

  /** What to do when the action button is clicked. For the delete action, we have to show a modal
      for confirmation. For other actions, do the API call immediately. */
  const handleWrapper = async (action: UserAction) => {
    switch (action) {
      case "delete":
        setDeleteModalOpened(true);
        return;
      case "reset_password":
        notify.info("Password reset code sent");
        await apiClient.resetPassword({
          userPoolId: userPool.userPoolId,
          resetPasswordRequest: {
            username: user.username,
            env: config.trEnv,
            clientId: userPool.clientId,
          },
        });
        return;
      case "send_invite":
        setResendModalOpened(true);
        return;
    }
  };

  const handleResend = async () => {
    setLoading(true);
    try {
      notify.info("New invite sent");
      await apiClient.resendInvite({
        userPoolId: userPool.userPoolId,
        resendInviteRequest: {
          username: user.email,
          productId: resendForm.values.product.productId,
          productUrl: resendForm.values.product.homeUrl,
          env: config.trEnv,
          clientId: userPool.clientId,
        },
      });
      setResendModalOpened(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await apiClient.deleteUser({
        userPoolId: userPool.userPoolId,
        deleteUserRequest: {
          username: user.username,
        },
      });
      notify.info("Account deleted");
      await onDeletion();
    } catch (e) {
      // TODO(kevin): Display an error once that design is finalized
      console.error(e);

      // NOTE: we only explicitly set this state in the error case because in
      // the success case, we re-fetch the user list which will automatically
      // unmount this component.
      setLoading(false);
      setDeleteModalOpened(false);
    }
  };

  const actionItems = availableActions.map(({ icon, action, text }) => {
    return (
      <ActionIcon
        key={action}
        role="button"
        aria-label={action}
        className={classNames(className, classes.actionButton)}
        onClick={() => handleWrapper(action)}
      >
        <Flex align="center">
          {icon}
          <span className={classes.actionText}>{text}</span>
        </Flex>
      </ActionIcon>
    );
  });

  return (
    <>
      {actionItems}
      <Modal
        opened={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        title="Delete account?"
        withCloseButton={false}
      >
        <Text>{`${userDisplayName(
          user
        )}'s account will be permanently deleted.`}</Text>
        <Group position="right" className={classes.buttonGroup}>
          <Button
            variant="subtle"
            onClick={() => setDeleteModalOpened(false)}
            className={classes.cancelButton}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={handleDelete}
            className={classes.deleteButton}
            aria-label="confirm-delete"
          >
            Delete
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={resendModalOpened}
        onClose={() => setResendModalOpened(false)}
        title="Resend invite"
        withCloseButton={false}
      >
        <Text className={classes.resendText}>
          Select the product that the user needs the invite for.
        </Text>
        <form>
          <Select
            value={
              resendForm.values.product
                ? getUniqueProductId(resendForm.values.product)
                : null
            }
            onChange={(uniqueProductId) => {
              const matchingProduct = products.find(
                (p) => getUniqueProductId(p) === uniqueProductId
              );

              if (matchingProduct) {
                resendForm.setFieldValue("product", matchingProduct);
              } else {
                console.error(
                  `No matching product for ${uniqueProductId ?? "(none)"} found`
                );
              }
            }}
            data={products.map((product) => {
              return {
                value: getUniqueProductId(product),
                label: product.uiName,
              };
            })}
            disabled={loading}
          />
        </form>
        <Group position="right" className={classes.buttonGroup}>
          <Button
            variant="subtle"
            onClick={() => setResendModalOpened(false)}
            className={classes.cancelButton}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={handleResend}
            className={classes.button}
            aria-label="confirm-resend"
          >
            Send invite
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  ...commonStyles(theme),
  actionButton: {
    cursor: "pointer",
    width: "auto",
    marginRight: "24px",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: theme.colour.Purple10,
    },
  },
  actionText: {
    marginLeft: "8px",
  },
  resendText: {
    marginBottom: "8px",
  },
  buttonGroup: {
    marginTop: "24px",
  },
  deleteButton: {
    backgroundColor: theme.colour.Red50,
  },
}));

export default UserActions;
