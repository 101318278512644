/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Name to show in the TRAM UI. Customers don't see this.
     * @type {string}
     * @memberof Product
     */
    uiName: string;
    /**
     * Canonical product ID, as defined in the user pool repo.
     * @type {string}
     * @memberof Product
     */
    productId: string;
    /**
     * URL to redirect the user to after they confirm their account.
     * @type {string}
     * @memberof Product
     */
    homeUrl: string;
}


/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uiName" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "homeUrl" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uiName': json['uiName'],
        'productId': json['productId'],
        'homeUrl': json['homeUrl'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uiName': value.uiName,
        'productId': value.productId,
        'homeUrl': value.homeUrl,
    };
}

